
import {
  convertDecimalWithComma,
  ellipsisString,
  formatDate,
  formatDateNormal,
  parsingErrorResponse,
} from "@/app/infrastructures/misc/Utils";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";
import { ApiStateData } from "@/domain/entities/Api";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  CBPPickup,
  CBPPickupListEntities,
  CBPPickupManifest,
} from "@/domain/entities/ShipmentBooking";
import debounce from "lodash/debounce";
import { FlagsPermissionCbp } from "@/feature-flags/flags-cbp";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    SelectSearchV2,
  },
  computed: {},
})
export default class TabListBookingClientCBP extends Vue {
  mounted() {
    this.onResetAdvanceFilter(false);
    this.getListClientCBP();
  }
  get isAbleCreateManifest() {
    return FlagsPermissionCbp.cbp_print_manifest.isEnabled();
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  async onPrintManifest() {
    const resp = await this.getDataManifest();
    MainAppController.showLoading();
    const print = await import("../manifest/cpb");

    if (resp?.success) {
      // logo
      const element: any = document;
      const logo = element.getElementById("logo").src;
      // handle data
      const data: any = [];
      resp?.resp.data.forEach((item: any, index: any) => {
        data.push({
          no: index + 1,
          sttNo: "",
          product: "",
          date: "",
          commodity: "",
          koli: "",
          grossWeight: "",
          volumeWeight: "",
          originCity: "",
          destCity: "",
          sttNoRefExternal: "",
        });
        const sttNo = data[index].sttNo ? "\n\n" : "";
        const product = data[index].product ? "\n\n" : "";
        const date = data[index].date ? "\n\n" : "";
        const koli = data[index].koli ? "\n\n" : "";
        const grossWeight = data[index].grossWeight ? "\n\n" : "";
        const originCity = data[index].originCity ? "\n\n" : "";
        const destinationCity = data[index].destCity ? "\n\n" : "";

        data[index] = {
          ...data[index],
          sttNo: `${data[index].sttNo}${sttNo}${item.sttNo}`,
          date: `${data[index].date}${date}${formatDateNormal(
            item.sttCreatedAt,
            "DD MMM YYYY"
          )}`,
          product: `${data[index].product}${product}${item.sttProductType}`,
          koli: `${data[index].koli}${koli}${item.sttTotalPiece}`,
          grossWeight: `${data[index].grossWeight}${grossWeight}${item.sttGrossWeight} Kg`,
          originCity: `${data[index].originCity}${originCity}${item.sttOriginCity}`,
          destCity: `${data[index].destCity}${destinationCity}${item.sttDestinationCity}`,
        };
      });
      print.printmanifestPdf(resp?.resp, data, logo);
    }
    MainAppController.closeLoading();
  }

  async getDataManifest() {
    try {
      MainAppController.showLoading();
      MainAppController.closeErrorMessage();
      const resp = await ShipmentBookingController.getManifestCBP(
        new RequestListBooking({
          pmcClientId: this.filter.pmcClientId?.id || "",
          pmcStatus: this.filter.pmcStatus,
          paymentStatus: "",
          isPaidUnpaid: false,
          cache: true,
          isTotalData: false,
          version: "1",
        })
      );
      return { success: true, resp };
    } catch (error) {
      if ((error as any).response.status === 404) {
        return;
      }
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Print Manifest!", () => this.getDataManifest())
      );
      return { success: false, resp: new CBPPickupManifest() };
    } finally {
      MainAppController.closeLoading();
    }
  }

  handleStyleStatusPrint(item: CBPPickup) {
    if (item.pmcStatus.toLowerCase() === "unprinted") return "";
    return "<div class='text-left mt-1 rounded-full flex flex-row items-center bg-green-lp-500 font-semibold text-green-lp-200 p-1'> <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><path fill-rule='evenodd' clip-rule='evenodd' d='M10 18.0001C12.1217 18.0001 14.1566 17.1572 15.6569 15.6569C17.1571 14.1566 18 12.1218 18 10.0001C18 7.87833 17.1571 5.8435 15.6569 4.34321C14.1566 2.84292 12.1217 2.00006 10 2.00006C7.87827 2.00006 5.84344 2.84292 4.34315 4.34321C2.84285 5.8435 2 7.87833 2 10.0001C2 12.1218 2.84285 14.1566 4.34315 15.6569C5.84344 17.1572 7.87827 18.0001 10 18.0001ZM13.707 8.70706C13.8892 8.51846 13.99 8.26586 13.9877 8.00366C13.9854 7.74146 13.8802 7.49065 13.6948 7.30524C13.5094 7.11983 13.2586 7.01467 12.9964 7.01239C12.7342 7.01011 12.4816 7.1109 12.293 7.29306L9 10.5861L7.707 9.29306C7.5184 9.1109 7.2658 9.01011 7.0036 9.01239C6.7414 9.01467 6.49059 9.11983 6.30518 9.30524C6.11977 9.49065 6.0146 9.74146 6.01233 10.0037C6.01005 10.2659 6.11084 10.5185 6.293 10.7071L8.293 12.7071C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.7071L13.707 8.70706Z' fill='#006644'/></svg>Sudah Manifest</div>";
  }

  get columns() {
    return [
      {
        name: "No. STT Genesis",
        styleHead: "w-50 text-left whitespace-no-wrap",
        styleBody: "text-red-lp-200 text-center font-montserrat",
        render: (item: CBPPickup) => {
          return `
          <div class='pl-1 text-left text-red-lp-200' style="color: #D11F40;">${
            item.sttNo
          }</div>
          ${this.handleStyleStatusPrint(item)}`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.clientName"),
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: CBPPickup) => {
          return `<div class="text-gray-lp-300 flex">
                    <div class="capitalize">
                        ${ellipsisString(item.sttBilledTo, 90)}
                    </div>
                  </div>`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.destinationAddress"),
        styleHead: "w-64 text-left",
        render: (item: CBPPickup) => {
          return `<div class="break-all text-gray-lp-300">${ellipsisString(
            item.sttRecipientAddress,
            30
          )}</div>`;
        },
      },
      {
        name: "3LC",
        styleHead: "w-24 text-left",
        render: (item: CBPPickup) => {
          return `<div class='flex text-gray-lp-300'>${item.sttDestinationCity}</div>`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.status"),
        styleHead: "w-56 text-left",
        render: (item: CBPPickup) => {
          return `<div class="text-gray-lp-300 flex">
                    <div class="rounded-full capitalize px-2 py-0 bg-gray-lp-400">
                        ${item.sttLastStatusId} (${item.sttCounter}/${item.sttTotalPiece})
                    </div>
                  </div>`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.bookingDate"),
        styleHead: "w-64 text-left",
        render: (item: CBPPickup) => {
          return `<div class="text-gray-lp-300">${formatDate(item.sttCreatedAt)}</div>
                  <div class="capitalize text-gray-lp-500">${ellipsisString(
                    item.sttCreatedName,
                    30
                  )}</div>`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.totalPieces"),
        styleHead: "w-24 text-left",
        render: (item: CBPPickup) => {
          return `<div class='flex text-gray-lp-300'>${item.sttTotalPiece}</div>`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.totalWeight"),
        styleHead: "w-40 text-left",
        render: (item: CBPPickup) => {
          return `<div class='flex text-gray-lp-300'>${convertDecimalWithComma(
            item.sttChargeAbleWeight,
            2
          )} kg</div>`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.serviceType"),
        styleHead: "w-40 text-left",
        render: (item: CBPPickup) => {
          return `<div class="text-gray-lp-300 flex">
                    <div class="rounded px-2 py-0 bg-gray-lp-400">
                        ${item.sttProductType}
                    </div>
                  </div>`;
        },
      },
      {
        name: this.$t("bookingShipment.list.table.commodity"),
        styleHead: "w-52 text-left",
        render: (item: CBPPickup) => {
          return `<div class="text-gray-lp-300 flex">
                    <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${ellipsisString(item.sttCommodityName, 25)}
                    </div>
                  </div>`;
        },
      },
    ];
  }

  async onResetAdvanceFilter(callList = true) {
    this.apiListData.loading = true;
    Object.assign(this.filter, {
      pmcClientId: "" as any,
      pmcStatus: "",
    });
    this.listData.pagination.page = 1;
    if (callList) await this.getListClientCBP();
  }

  get isEmpty(): boolean {
    return !this.listData.data.length;
    // add no filter
  }

  get disabledCreateManifest() {
    return !this.filter.pmcClientId?.name?.length || !this.filter.pmcClientId;
  }
  filter = {
    pmcClientId: "" as any,
    pmcStatus: "",
  };

  apiListData = new ApiStateData();
  listData = new CBPPickupListEntities({});

  onSearchListPosCbp = debounce((search: string) => {
    PartnerController.getClientNamePOS({
      search,
      page: 1,
      limit: 10,
      isBranch: true,
      isApiCbp: true,
      packageType: "",
      cityCode: "",
      isDirectory: false
    });
  }, 250);

  get isLoadingPosCbp() {
    return PartnerController.loadingClientsNamePOS;
  }

  get posCbp() {
    return PartnerController.clientDataNamePOS.data.map((e: any) => ({
      id: e.clientId,
      name: `${e.clientCode} - ${e.clientName}`,
      status: e.clientStatus,
    }));
  }

  onSelectPosCbp() {
    Object.assign(this.filter, {
      pmcClientId: this.filter.pmcClientId || "",
    });
    this.onChangeFilter();
  }

  onChangeFilter() {
    this.listData.pagination.page = 1;
    this.getListClientCBP();
  }

  async getListClientCBP() {
    this.apiListData.loading = true;
    try {
      const resp = await ShipmentBookingController.getBookingListCBP(
        new RequestListBooking({
          page: this.listData.pagination.page,
          pmcClientId: this.filter.pmcClientId?.id || "",
          pmcStatus: this.filter.pmcStatus,
          limit: 20,
          paymentStatus: "",
          isPaidUnpaid: false,
          cache: true,
          isTotalData: true,
          version: "1",
        })
      );
      this.listData = new CBPPickupListEntities({
        pagination: new PaginationV2({
          page: resp.pagination.page,
          limit: resp.pagination.limit,
          totalData: resp.pagination.totalData,
        }),
        data: resp.data,
      });
      this.apiListData.errorType = "";
    } catch (error) {
      this.apiListData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiListData.loading = false;
    }
  }

  statusPrintManifest = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Sudah Manifest",
      value: "printed",
    },
    {
      label: "Belum Manifest",
      value: "unprinted",
    },
  ];

  changeStatusPrintManifest(value: any) {
    if (value === this.filter.pmcStatus) return;
    Object.assign(this.filter, {
      pmcStatus: value || "",
    });
    this.onChangeFilter();
  }

  tabKeyActive = "";

  isTabActive(key: string) {
    return key === this.filter.pmcStatus;
  }
}
